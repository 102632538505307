import React from 'react'
import '../styles/ForgetPage.css'
import Header from './Header'
import Footer from './Footer'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const ForgetPage = () => {
  return (
    <div>
        <Header />
            <main>
                <div className='forgot-container'>
                    <div className='forgot-container1'>
                        <div>
                            <h2>Forgot Your Password ?</h2>
                            <p>Enter your registered email address to reset your password</p>
                        </div>
                        <div>
                            <form>
                                <input type='email' placeholder='Enter Your Email Address' className='emailname'/>
                            </form>
                            <Link to="/login"className='btn btn-danger m-3'>Cancel</Link>
                            &nbsp;<button className='btn btn-danger'>Continue</button>
                        </div>
                    </div>
                </div>
            </main>
        <Footer />
      
    </div>
  )
}

export default ForgetPage
