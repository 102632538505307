import React from 'react'
import '../styles/About.css'
import Header from './Header';
import Hand from '../image/pic-1.jpg'
import numplate from '../image/about_us_1.jpg'
import modern from '../image/about_us_2.jpg'
import home from '../image/about_us_3.jpg'
import Footer from './Footer'

const About = () => {
  return (
    <>
      <Header />
      <main>
         {/* article1 */}
        <article className="top_strip">
          <h2>About Us</h2>
        </article>

         {/* article2 */}
         <article className='company-overview'>
            <div className="about-content">
                <div> 
                    <img src={Hand} className="pc-holder" />
                </div>
                <div> 
                  <div className="overview">
                    <h2>Company Overview</h2>
                    <ul>
                      <li> Founded in 1950</li>
                      <li> 50 Countries use our plates</li>
                      <li> German standards DIN 74069 &amp; ISO 7591 </li>
                      <li> Highest Quality standards</li>
                      <li> Novelis Aluminum - high quality plates</li>
                      <li> 3M Retro-reflective sheeting</li>
                      <li> The “On-Top” combination of embossing press/hot-stamping machine combines a 35t embossing press and a high-performance machine on just 0.75 m2</li>
                      <li> Automated production line: the production line can be put together to completely suit individual requirements.</li>
                      <li> 280,000  license plate blanks can be manufactured per day.</li>
                    </ul>
                  </div>
                </div>
              
            </div>
          </article>


        {/* article3 */}
        <article className='about-us-vision'>
          <div className="container">
            <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <h4 className='our-vision'>Our Vision</h4>
                  <p className='our-vision-para'>The Benchmark in High Security Registration Plates (HSRP).The Benchmark in High Security Registration Plates.</p>
                  <h4 className='our-vision'>Quality</h4>
                  <p className='our-vision-para'>Yarya Sekur has always been a beacon of Quality in all our product offerings. The business of secure number plates should always reflect quality to ensure a safe system in durability and security.</p>
                  <h4 className='our-vision'>Service</h4>
                  <p className='our-vision-para'>Yarya Sekur believes in offering the best service parameters to both our OEM customers as well as to all vehicle dealers. </p>
                </div>
                <div className="col-lg-6 ">
                  <img src={numplate} height="450px" width="500px"/>
                </div>
            </div> 
          </div> 
        </article>

        {/* article4 */}
        <article className="about_btm">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12"><h2>German Factory / Indian Greenfield</h2></div>
                  <div className="col-lg-6 about_btm_inner">
                      <img src={modern} />
                      <h3>MODERN</h3>
                      <h4>EFFICIENT</h4>
                      <h5>DIN 74069 ISO 9001</h5>
                      <p>The modern factory located near Hanover is a fully automated unit. Its an integrated manufacturing setup able to produce blank plates and all the tools and die sets.</p>
                  </div>

                  <div className="col-lg-6 about_btm_inner">
                      <img src={home}/>
                      <h3>MODERN</h3>
                      <h4>EFFICIENT</h4>
                      <h5>DIN 74069 ISO 9001</h5>
                      <p>The modern factory located near Hanover is a fully automated unit. Its an integrated manufacturing setup able to produce blank plates and all the tools and die sets.</p>
                  </div>
              </div>
          </div>
        </article>
      </main>
      <Footer />
    </>
  )
}

export default About
