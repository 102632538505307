import React from 'react'
import Header from './Header';
import Footer from './Footer';
const Refundcancellationpolicy = () => {
  return (
    <>
    <Header />
    <main>
      <article className="top_strip">
          <h2>REFUND & CANCELLATION POLICY</h2>
      </article>
      <article className="about_us_inner services">
        <div className="container">
            <div className="row">

                <div className="col-lg-12">
                    <p>Refunds are applicable only in case where the damaged product or incorrect size of HSRP is processed. Returns and Replacements are done free of cost if wrong product, wrong size or damaged product are processed.</p>

                    <h3>Return Intimation</h3>
                    <p>You can either call us at Support Number : 8595515301 or Email: support@yaryasekur.com.</p>
                    <p>The reason of return (providing Order number, and Reason) should be shared with the Customer Support by email.</p>

                    <h3>Cancellation Policy</h3>
                    <p>Easy cancellation process is available for all our esteemed dealers/customers. You can cancel your online order within 24 hrs. of ordering.</p>
                    <p>After successful cancellation charged amount for the order will be refunded within six-eight working days.</p>
                    <p>You can cancel an order in easy step just by clicking on order cancellation tab and submitting required details.</p>
                    <p>For any further query or clarity please call us on our support number 8595515301 or Email us at support@yaryasekur.com.</p>
                    <p>Please note that the complete order needs to be cancelled. We do not accept Partial order cancellation requests.</p>

                    <h3>Refund Policy</h3>
                    <p>After successful cancellation, your order amount will be refunded within six- eight working days. Please note that the timing of actual account credit is dependent on multiple external agencies i.e. banks/payment gateways. The refund amount will be credited back into the same account that was used to make the payment.</p>
                    
                </div>
            </div>
        </div>
      </article>
    </main>
    <Footer/>

    </>
  )
}

export default Refundcancellationpolicy
