import React from 'react'
import Header from './Header'
import service from '../image/service_1.jpg'
import plateimagenum from '../image/service_3.jpg'
import carserviceimg from '../image/service_4.jpg'
import hsrpserviceimg from '../image/HSRP-car.jpg'
import management from '../image/Management-pic.jpg'
import Footer from './Footer';


import '../styles/Services.css'
const Services = () => {
  return (
    <>
      <Header/>
      <main>
        {/* article1 */}        
        <article className="top_strip">
          <h2>Our Services</h2>        
        </article>

       {/* article2 */}  
        <article className="services">
          <div className="container">
              <div class="col-lg-12">
                  <h3>HSRP Plate Manufacturing</h3>
                  <p>
                      The most modern production line in India. A state-of-the-art process - Fully automated, computer-controlled production of vehicle license plates in large quantities requires precise planning and costing. The support we provide is based on many years of experience and it includes additional assistance in organizational, design and security matters.
                  </p>
                  <img src={service} />
              </div>
              <div className="col-lg-12" style={{padding : "50px 0px"}}>
                <h3>Manufacturing Licence</h3>
                <div className="container" style={{width:"60%"}}>
                    <img src={'#'}/>
                </div>
              </div>              
          </div>      
        </article>

        {/* article3 */}
        <article className="qr-code qrcodecolor">
          <div className="col-lg-12 code-basing">
                <h3>QR Code based Embossing Stations</h3>
                <p>
                    We are the only company to use a QR Code based system for matching the UID and registration number. The Yarya 50T embossing press is used to produce embossed vehicle license plates in accordance with DIN 74069/ISO 7591/AIS 159. Its powerful and robust construction makes it suitable for large production runs. Its ergonomic construction requires no additional tabletop. Embossing tools can be stored in the shelves provided. A two-hand control system ensures safe operation.
                </p>
                <img src={plateimagenum}/>
            </div>
        </article>

        {/* article4 */} 
        <article className="qr-code">
          <div className="col-lg-12 code-basing">
                <h3>OEM / Retrofit</h3>
                <p>
                  From April 2019 the Indian Government implemented the HSRP plates through the vehicle manufacturers for the new vehicles. Many states have started to implement HSRP for vehicles registered before April 2019 as a retrofit. The advantages are clear – prevents use of stolen vehicles, tamper proof plates affixed with snap locks, vehicles registered on Vahan databse.
                </p>
                <img src={carserviceimg}/>
            </div>
        </article>

        {/* article5 */} 
        <article className="qr-code">
          <div className="col-lg-12 code-basing">
                <h3>Governing Rules of (HSRP)</h3>
                <p>
                  HSRPs are being implemented for the essential details of a car, including its engine number and chassis number, are stored in a centralised database. The stored data along with the 10-digit PIN can be used to identify a stolen car.
                </p>
                <img src={hsrpserviceimg}/>
            </div>
        </article>

        {/* article6 */} 
        <article className="qr-code">
          <div className="col-lg-12 code-basing">
                <h3>IT– Application/Management</h3>
                <p>
                  T Protocols.                
                </p>
                <img src={management}/>
            </div>
        </article>
      </main>
      <Footer />
    </>
  )
}

export default Services
