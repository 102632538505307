import React,{useEffect} from 'react'
import { useLocation, BrowserRouter, Routes, Route, Link } from 'react-router-dom';
// import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Home from './Home'
import About from './About'
import Services from './Services'
import Faq from './Faq'
import Contact from './Contact'
import Login from './Login'
import Privacypolicy from './Privacypolicy'
import Termsconditions from './Termsconditions'
import Refundcancellationpolicy from './Refundcancellationpolicy'
import ForgetPage from './ForgetPage'
import Vandanatable from './Vandanatable';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Navbar = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* top */}
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/service' element={<Services />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/login' element={<Login />} />
        {/* top */}
        {/* bottom */}
        <Route path='/privacy' element={<Privacypolicy />} />
        <Route path='/terms' element={<Termsconditions />} />
        <Route path='/refund' element={<Refundcancellationpolicy />} />
        {/* bottom */}
        {/* forgetpasswordpage */}
        <Route path='/forgetpage' element={<ForgetPage />} />
        {/* invoice vanatha table */}
        <Route path='/invoice' element={<Vandanatable/>}/>

      </Routes>
    </BrowserRouter>
  )
}

export default Navbar;
