import React,{useState} from 'react'
import Header from './Header'
import '../styles/Contact.css'
import mail from '../image/mail-ico.png'
import phone from '../image/phone-ico.png'
import pin from '../image/pin-icon.png'
import Footer from './Footer'

const Contact = () => {
  const[namefocused,setNameFocused] = useState(false)
  const[emailfocused,setEmailFocused] = useState(false)
  const[mobilefocused,setMobileFocused] = useState(false)
  const[areatextfocused,setAreaTextFocused] = useState(false)

  const mouseClickedName= ()=>{
    setNameFocused(true)
    setEmailFocused(false)
    setMobileFocused(false)
    setAreaTextFocused(false)
  }
  const mouseClickedEmail= ()=>{
    setNameFocused(false)
    setEmailFocused(true)
    setMobileFocused(false)
    setAreaTextFocused(false)
  }
  const mouseClickedTel= ()=>{
    setNameFocused(false)
    setEmailFocused(false)
    setMobileFocused(true)
    setAreaTextFocused(false)
  }
  const mouseClickedArea= ()=>{
    setNameFocused(false)
    setEmailFocused(false)
    setMobileFocused(false)
    setAreaTextFocused(true)
  }
  const mouseNotClicked= ()=>{
    setNameFocused(false)
    setEmailFocused(false)
    setMobileFocused(false)
    setAreaTextFocused(false)
  }
  return (
    <>
      <Header />
      <main>
        {/* article1 */}
        <article className="top_strip">
          <h2>Contact Us</h2>
        </article>
        
        {/* article2 */}
        <article className="map-contact">
          <div className="container">
            <div className="row" id="parent d-flex flex-row justify-content-center">
              <div className="col-sm-6">
                  <iframe  height="500" width="500" id="gmap_canvas" src="https://maps.google.com/maps?q=E-99,%20(Old%20E2)%204th%20Floor,%203rd%20Ave,%20Block%20E,%20Annanagar%20East,%20Chennai,%20Tamil%20Nadu%20600102&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
              </div>
              <div className="col-sm-6 contact_us_frm">
                <h2>Get a Free Quote</h2>
                <h5>Cum sociis natoque penatibus et magnis dis parturient montes,</h5>            
                  <form className='free-quote-name'>
                    <input type='text' placeholder='Name' className='name' onFocus={mouseClickedName} onBlur={mouseNotClicked} style={{borderBottom: namefocused ? 'solid 2px red' : 'solid 2px #8c8a84'}}/>  <br/>
                    <input type='Email' placeholder='Email' className='name' onFocus={mouseClickedEmail} onBlur={mouseNotClicked} style={{borderBottom: emailfocused ? 'solid 2px red' : 'solid 2px #8c8a84'}} /> <br/>
                    <input type='tel' placeholder='Mobile No.' className='name' onFocus={mouseClickedTel} onBlur={mouseNotClicked} style={{borderBottom: mobilefocused ? 'solid 2px red' : 'solid 2px #8c8a84'}} /> <br/>
                    <textarea rows='5' placeholder='Type Your Message/Feedback here...' className='name' onFocus={mouseClickedArea} onBlur={mouseNotClicked} style={{borderBottom: areatextfocused ? 'solid 2px red' : 'solid 2px #8c8a84'}}></textarea>
                    <button className='btn btn-danger' type="submit" style={{backgroundColor:'#D51D1D'}}>SEND</button>
                  </form>
              </div>
            </div>
          </div>
        </article>

        {/* article3 */}
        <article className="mail-website">
        <div className="container contact-datas">
              <div className='container-image'>
                <img src={mail} className='mail-image'/>
                <div className="mail-container">
                  <h1>MAIL & WEBSITE</h1>
                  <p>info@yaryasekur.com <br/><span>www.yaryasekur.com</span></p>
                </div>
              </div>
              <div className='container-image'>
                <img src={phone} className='mail-image'/>
                <div className="mail-container mail-container1">
                  <h1>CONTACT</h1>
                  <p>(+91)-756706XXXX</p>
                </div>
              </div>
              <div className='container-image'>
                <img src={pin} className='mail-image'/>
                <div className="mail-container">
                  <h1>ADDRESS</h1>
                  <p>E-99, 4 th Floor, Third Avenue,<br/><span>Anna Nagar, Chennai-600102.</span></p>
                </div>
              </div>
          </div>
        </article>
      </main>
      <Footer/>
    </>
  )
}

export default Contact
