import React,{useState,useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './NavbarStyles.css'; 
import yarayasekur from '../image/logo (1).png'
import carchromium from '../image/slider-1.jpg'
import icon1 from '../image/ico-1.png'
import icon2 from '../image/ico-2.png'
import icon3 from '../image/ico-3.png'
import newsicon from '../image/news-icon.png'
import management from '../image/Management-pic.jpg'
import Footer from './Footer';
import Header from './Header';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const Home = () => {
    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '600px',
        cursor:'pointer'
      }
    const divStyle1={
        fontSize: '14px',
        paddingLeft:'5px'
      }
    const slideImages = [
        {
          url:carchromium ,
        }
      ];
    const slideNewsActivity = [ 
        "The Benchmark in High Security Registration Plates (HSRP).",
        "Yarya Sekur has always been a beacon of Quality in all our product offerings. The business of secure number plates should always reflect quality to ensure a safe system in durability and security.",
        "Yarya Sekur believes in offering the best service parameters to both our OEM customers as well as to all vehicle dealers."
    ];
    const slideRef = useRef(null);
    const [activeButton, setActiveButton] = useState(); // State to manage active button index

    // const handleSlideChange = (currentIndex) => {
    //     console.log(currentIndex)
    //     setActiveButton(currentIndex-1); // Update the active button index when the slide changes
    // };
    // onChange={handleSlideChange}

    const handleButtonClick = (index) => {
        if (slideRef.current) {
            slideRef.current.goTo(index); // Move the slider to the specified index            
        }
        setActiveButton(index);
    };
  return (
    <>
        <Header />
        <main>
            <article>
            <div className="slide-container">
                <Slide >
                    {slideImages.map((slideImage, index)=> (
                        <div key={index} >
                            <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }} >
                            </div>
                        </div>
                    ))} 
                </Slide>
            </div>
            </article>
            <article className='hsrp-section'>
                <div className='section-2'>
                    <h2>HSRP <span>HSRP</span></h2>
                    <p className="section-2-content">Completely new green field factory to be fully operational by Q2 2020. Capable of producing 80,000 blanks a day. Will become the hub for both Indian and Asian markets.</p>
                    <div className='img-container'>
                        <div className='section-2-left-image'>
                            <img src={icon1} className='oem-img'/>
                            <h3>OEM</h3>
                        </div>
                        <div className='section-2-center-image'>
                            <img src={icon2} className='oem-img'/>
                            <h3>OEM Dealer</h3>
                        </div> 
                        <div className='section-2-right-image' >
                            <img src={icon3} className='oem-img'/>
                            <h3>HSRP User</h3>
                        </div>
                    </div> 
                </div>
            </article>
            <article className='hsrp-section1'>
               <div className='hsrp-section1-container left'>
                 <p>Yarya Sekur believes in offering the best service parameters to both our OEM customers as well as to all vehicle dealers. </p>
                 <button>Make an Inquiry</button>
                </div>
                <div className='right'>
                    <h4><img src={newsicon} /> News &amp; Activity</h4>
                    <Slide ref={slideRef} > 
                        {slideNewsActivity.map((news, index)=> (
                            <div key={index}>
                                <div style={{ ...divStyle1 }} >
                                    <p>{news}</p>
                                </div>
                                
                            </div>
                        ))} 
                    </Slide>
                    <div className='d-flex flex-row justify-content-center'>
                        <div className= {activeButton === 0 ? 'slider-button active right-button-slide' : 'slider-button right-button-slide'}  onClick={() => handleButtonClick(0)}></div>
                        <div className= {activeButton === 1 ? 'slider-button active right-button-slide' : 'slider-button right-button-slide'}  onClick={() => handleButtonClick(1)}></div>
                        <div className= {activeButton === 2 ? 'slider-button active right-button-slide' : 'slider-button right-button-slide'}  onClick={() => handleButtonClick(2)}></div>
                    </div>  
                </div>
            </article>
            <article className='hsrp-section2'>
                <div className='how_we_work'>
                   <h2>How do<span> We work</span></h2>
                   <p>Yarya Sekur believes in offering the best service parameters to both our OEM customers as well as to all vehicle dealers.</p>
                </div>
                <div className="services">
                    <div className="services-card">
                        <img src={management}  className='sevices-img'/>
                    </div>
                </div>
            </article>
            <article className="about_us">
                <div className="about_us-container">
                    <h2>About <span>Us</span></h2>
                    <p className='founded-desc'>Founded in 1950, 50 Countries use our plates</p>
                    <p className='space-content'>
                        The “On-Top” combination of embossing press/hot-stamping machine combines a 35t embossing press and a high-performance machine on just 0.75 m2,
                    </p>
                    <br/>
                    <p className='space-content'>
                        Automated production line: the production line can be put together to completely suit individual requirements,
                    </p>
                    <br/>
                    <p className='space-content'>
                        280,000 license plate blanks can be manufactured per day,
                    </p>
                    <br/>
                    <button>Read More</button>
                </div>
            </article>
            <article className="testimonial d-flex flex-column justify-content-center">
                <div className='container'>
                    <Slide>
                        <p className="testimonial_content">The most modern production line in India. A state-of-the-art process - Fully automated, computer-controlled production of vehicle license plates in large quantities requires precise planning and costing. The support we provide is based on many years of experience and it includes additional assistance in organizational, design and security matters </p>
                    </Slide>
                </div>                
            </article>
        </main>
        <Footer />
    </>
  );
};

export default Home;
