import React from 'react'
import './NavbarStyles.css'; // Import a separate CSS file for styling
import logofoot from '../image/logo-foot.png'  
import { Link } from 'react-router-dom';
import facebookimg from '../image/facebook-ico.png';
import linkedinimg from '../image/linked-in-ico.png';
import twitterimg from '../image/twitter-ico.png';
import pinterestimg from '../image/pin-ico.png';



const Footer = () => {
   const facebookPageUrl = 'https://www.facebook.com/your-facebook-page-url'
   const linkedin = 'https://www.linkedin.com/'
   const twitter = 'https://twitter.com/'
   const pinterest = 'https://in.pinterest.com/'
   return (
        <>
            <div className='footer'>
                <div className="foot-logo">
                    <img src={logofoot}/>
                </div>
                <div>
                    <ul className='footer-content-navbar'>
                        <li><Link to='/about' className='Link-footer'>About Us</Link></li>  
                        <li><Link to='/contact' className='Link-footer'>Contact Us</Link></li>                 
                        <li><Link to='/privacy' className='Link-footer'>Privacy Policy</Link></li> 
                        <li><Link to='/terms' className='Link-footer'>Terms & Conditions</Link></li> 
                        <li><Link to='/refund' className='Link-footer1'>Refund & Cancellation Policy</Link></li>
                    </ul>
                </div>
                
                <div className="d-flex flex-row justify-content-center p-3">
                    <a href={facebookPageUrl} target="_blank" rel="noopener noreferrer">
                        <img src={facebookimg} height="30px" />
                    </a> &nbsp; &nbsp;
                    <a href={linkedin} target="_blank" rel="noopener noreferrer">
                        <img src={linkedinimg} height="30px" />
                    </a>&nbsp; &nbsp;
                    <a href={twitter} target="_blank" rel="noopener noreferrer">
                        <img src={twitterimg} height="30px" />
                    </a>&nbsp; &nbsp;
                    <a href={pinterest} target="_blank" rel="noopener noreferrer">
                        <img src={pinterestimg} height="30px" />
                    </a>
                </div>
                <div class="btm_row">
                    <span>Copyright  2020 | All rights reserved</span>                
                </div>
            </div>
        </>
    )
}

export default Footer
