import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';
import './NavbarStyles.css'; // Import a separate CSS file for styling
import yarayasekur from '../image/logo (1).png'
import usericon from '../image/user-icon.png';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

const handleLogin = () => {
    // Redirect to another website
    window.location.href = 'https://portal.hsrp.fit/#/login'; 
  };
 
  return (
    <>
        <header>
            <div className="logo">
                {/* Add your logo or any other content on the left side */}
                <img src={yarayasekur}/>
            </div>
            <div className="menu-icon" onClick={toggleMenu}>
                {/* Use an icon library (e.g., Font Awesome) for the menu icon */}
                <i className={`fas ${showMenu ? 'fa-times' : 'fa-bars'}`}></i>
            </div>
            <nav className={showMenu ? 'show' : ''}>
                {/* Navigation links */}
                <Link to='/' className='Link'>HOME</Link> <span className='dot-header'>.</span>
                <Link to='/about' className='Link'>ABOUT US</Link> <span className='dot-header'>.</span>
                <Link to='/service' className='Link'>OUR SERVICES</Link> <span className='dot-header'>.</span>
                <Link to='/faq' className='Link'>FAQ</Link> <span className='dot-header'>.</span>
                <Link to='/contact' className='Link'>CONTACT US</Link> <span className='dot-header'>.</span>                        
                {/* <Link to='/invoice' className='Link'>INVOICE</Link> */}
                {/* <Link to='/login' className='Link' > <img src={usericon} className="user-icon"/>&nbsp;LOGIN</Link> */}
                <Link onClick={handleLogin} className='Link'  > <img src={usericon} className="user-icon"/>&nbsp;LOGIN</Link>                
            </nav>
        </header>
    </>
  )
}

export default Header
