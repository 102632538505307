import React from 'react'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logofoot from '../image/logo-foot.png'
import '../styles/Vandanatable.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const Vandanatable = () => {
    const handleDownloadPDF = () => {
        const input = document.getElementById('pdfDatas');
        const tableWidth = input.offsetWidth;
        const tableHeight = input.offsetHeight;
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            // const pdf = new jsPDF();
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210; // A4 width in mm
            const imgHeight = (tableHeight * imgWidth) / tableWidth;
            // pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save('table.pdf');
          });
          
      };    
  return (
    <> 
       <div  className='download-buttom d-flex flex-row justify-content-end'>
            <button onClick={handleDownloadPDF} className='download-pdf'>
               <FontAwesomeIcon icon={faDownload} />Download Pdf
            </button>
        </div>
       <div id='pdfDatas'>
            <div className='invoice-container d-flex flex-row justify-content-center'>
                <img src={logofoot} className='invoice-img'/>
                <div className='invoice-container-content'>
                    <h1 className='invoice-heading'>YARYA SEKUR</h1>
                    <p className='invoice-desc'>High Security Registration Plates</p>
                </div>
            </div>
            
            <div className='invoice-container-divs d-flex flex-row'>
                <div className='item1'></div>
                <div className='item2'></div>
                <div className='item3'></div>
            </div>
            <div className='invoice-container-link'>
                <a href={'https://hsrp.fit/'}>www.yaryasekur.com</a>
            </div>
            <div className='table-container'>
                <table  style={{ borderCollapse: 'collapse' }} className='table-styled'>
                    <thead className='table-heading'>
                        <tr>
                            <th colSpan='6' style={{ border: '1px solid black' }} className='annexture'>Annexture For Delivery Receipt</th>
                        </tr>
                        <tr>
                            <td rowSpan='2' colSpan='4' style={{ border: '1px solid black' }}>Details of the Plates</td>
                            <td colSpan='1' style={{ border: '1px solid black' }}>Invoice No</td> 
                            <th colSpan='1' style={{ border: '1px solid black' }}>YS01062022138</th>
                        </tr>
                        <tr>
                            <td colSpan='1' style={{ border: '1px solid black' }}>Date</td>
                            <th colSpan='1' style={{ border: '1px solid black' }}>01-06-2022</th>
                        </tr>
                        <tr>
                            <th colSpan='4' style={{ border: '1px solid black' }} className='empt-row'> </th>
                            <th colSpan='2' style={{ border: '1px solid black' }} className='empt-row'> </th>
                        </tr>
                        <tr>
                            <th style={{ border: '1px solid black' }}>SI No</th>
                            <th style={{ border: '1px solid black' }}>Vehicle No</th>
                            <th style={{ border: '1px solid black' }}>Type of Vehicle</th>
                            <th style={{ border: '1px solid black' }}>Vehicle Class</th>
                            <th style={{ border: '1px solid black' }}>Front Laser Code</th>
                            <th style={{ border: '1px solid black' }}>Rear Laser Code</th>
                        </tr>
                    </thead>
                    <tbody className='table-body'>
                    {/* You can dynamically generate table rows here */}
                    <tr>
                        <td style={{ border: '1px solid black' }}>1.</td>
                        <td style={{ border: '1px solid black' }}>CG11BF0885</td>
                        <td style={{ border: '1px solid black' }}>Trailer/Tractor Trolley</td>
                        <td style={{ border: '1px solid black' }}>Non-Transport</td>
                        <td style={{ border: '1px solid black' }}>YB2000000333</td>
                        <td style={{ border: '1px solid black' }}>YB3400000201</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black' }}>2.</td>
                        <td style={{ border: '1px solid black' }}>CG12BG4607</td>
                        <td style={{ border: '1px solid black' }}>Trailer/Tractor Trolley</td>
                        <td style={{ border: '1px solid black' }}>Non-Transport</td>
                        <td style={{ border: '1px solid black' }}>YB2000000332</td>
                        <td style={{ border: '1px solid black' }}>YB3400000200</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black' }}>3.</td>
                        <td style={{ border: '1px solid black' }}>CG11BE8837</td>
                        <td style={{ border: '1px solid black' }}>Trailer/Tractor Trolley</td>
                        <td style={{ border: '1px solid black' }}>Non-Transport</td>
                        <td style={{ border: '1px solid black' }}>YB2000000271</td>
                        <td style={{ border: '1px solid black' }}>YB3400000181</td>
                    </tr>
                    {/* You can add more rows dynamically */}
                    </tbody>
                    <tfoot className='table-footer'>
                        <tr>
                            <td colSpan='6' style={{ border: '1px solid black' }} className='empt-row'> </td>
                        </tr>
                        <tr>
                            <td colSpan='2' style={{ border: '1px solid black' }}>
                                <article>
                                    Acknowledgement by Dealer
                                </article>
                            </td>
                            <td colSpan='2' style={{ border: '1px solid black' }}> 
                                <article>
                                    Date of Receiving
                                </article>
                            </td>
                            <td colSpan='2' style={{ border: '1px solid black' }}> 
                                <article>
                                    For Yarya Sekur Mark Pvt Ltd 
                                </article>
                                <article className='director-sign'>
                                    
                                </article>
                                <article>
                                    Director
                                </article>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>       
    </>
  )
}

export default Vandanatable
