import React from 'react'
import Header from './Header';
import Footer from './Footer';
const Termsconditions = () => {
  return (
    <>
      <Header />
      <main>
        <article className="top_strip">
            <h2>TERMS & CONDITIONS</h2>
        </article>
        <article className="about_us_inner services">
            <div className="container">
                <div className="row">

                    <div className="col-lg-12">
                        <h3>1. Disclaimer</h3>
                        <p>I hereby acknowledge and undertake that I am accessing the services on https://hsrp.fit/ Company website and transacting at my own risk. https://hsrp.fit Company will neither be liable nor responsible for any actions or inactions by the vehicle dealer for the purpose of affixation on whose behalf  https://hsrp.fit is collecting the affixation fee nor hereby expressly disclaim any and all responsibility and liability in this regard. https://hsrp.fit Company is only responsible to deliver HSRP Plates at the Dealer location within the stipulated time.</p>
                        <p>It is the responsibility of Vehicle dealer to install the HSRP on the vehicle as per the guidelines of Statutory Authorities. https://hsrp.fit Company will not arbitrate or resolve any dispute or disagreement between vehicle owner and the vehicle dealer before or after fitment process.</p>
                        <p>I/we have read the Terms &amp; Conditions/FAQ/Privacy Policy, any legal dispute arise jurisdiction will be only in NCT of Delhi.</p>

                        <h3>2. License to use website</h3>
                        <p><strong>(i)</strong> You may:</p>
                        <ul>
                            <li>View pages from our website in a web browser.</li>
                            <li>Download pages from our website for caching in a web browser.</li>
                            <li>Print pages from our website.</li>
                            <li>Stream audio and video files from our website.</li>
                            <li>Use our website services by means of a web browser.</li>
                        </ul>
                        <p>Subject to the other provisions of these terms and conditions and other stipulation and made in FAQ, Privacy Policy and Refund-Return-Policy any fraud or miss used said be user liability.</p>
                        <p><strong>(ii)</strong> Except as expressly permitted by the provisions of these terms and conditions, you must not download any material from our website or save any such material to your computer.</p>
                        <p><strong>(iii)</strong> You may only use our website for [business purposes], and you must not use our website for any other purposes.</p>
                        <p><strong>(iv)</strong> Except as expressly permitted by these terms and conditions, you must not edit or otherwise modify any material on our website.</p>
                        <p><strong>(v)</strong> Unless you own or control the relevant rights in the material, you must not:</p>
                        <ul>
                            <li>Republish material from our website (including republication on another website).</li>
                            <li>Sell, rent or sub-license material from our website.</li>
                            <li>Show any material from our website in public.</li>
                            <li>Exploit material from our website for a commercial purpose.</li>
                            <li>Redistribute material from our website.</li>
                        </ul>
                        <p><strong>(vi)</strong> We reserve the right to restrict access to areas of our website, or indeed our whole website, at our discretion; you must not circumvent or bypass, or attempt to circumvent or bypass, any access restriction measures on our website.</p>

                        <h3>3. Acceptable use</h3>
                        <p><strong>(i)</strong> You must not:</p>
                        <ul>
                            <li>Use our website in any way or take any action that causes, or may cause, damage to the website or impairment of the performance, availability or accessibility of the website.</li>
                            <li>Use our website in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</li>
                            <li>Use our website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</li>
                            <li>Violate the directives set out in the robots.txt file for our website.</li>
                            <li>Use data collected from our website for any direct marketing activity (including without limitation email marketing, SMS marketing, telemarketing and direct mailing).</li>
                        </ul>
                        <p><strong>(ii)</strong> You must not use data collected from our website to contact individuals, companies or other persons or entities.</p>
                        <p><strong>(iii)</strong> You must ensure that all the information you supply to us through our website, or in relation to our website, is true, accurate, current, complete and non-misleading.</p>

                        <h3>4. Registration and accounts</h3>
                        <p><strong>(i)</strong> You may register for an account with our website by completing and submitting the account registration form on our website and clicking on the verification link in the email that the website will send to you.</p>
                        <p><strong>(ii)</strong> You must not allow any other person to use your account to access the website.</p>
                        <p><strong>(iii)</strong> You must notify us in writing immediately if you become aware of any unauthorized use of your account.</p>
                        <p><strong>(iv)</strong> You must not use any other person's account to access the website unless you have that person's express permission to do so.</p>

                        <h3>5. User login details</h3>
                        <p><strong>(i)</strong> If you register for an account with our website, we will provide you with OR you will be asked to choose a user ID and password.</p>
                        <p><strong>(ii)</strong> Your user ID must not be liable to mislead, you must not use your account or user ID for or in connection with the impersonation of any person.</p>
                        <p><strong>(iii)</strong> You must keep your password confidential.</p>
                        <p><strong>(iv)</strong> You must notify us in writing immediately if you become aware of any disclosure of your password.</p>
                        <p><strong>(v)</strong> You are responsible for any activity on our website arising out of any failure to keep your password confidential, and may be held liable for any losses arising out of such a failure.</p>

                        <h3>6. Cancellation and suspension of account</h3>
                        <p><strong>(i)</strong> We may:</p>
                        <ul>
                            <li>Suspend your account.</li>
                            <li>Cancel your account.</li>
                            <li>Edit your account details.</li>
                            <li>Take any meatuses in addition to above so restrict your use of this website.</li>
                        </ul>
                        <p>At any time in our sole discretion without notice or explanation.</p>
                        <p><strong>(ii)</strong> You may cancel your account on our website using your account control panel on the website.</p>

                        <h3>7. Your content: license</h3>
                        <p><strong>(i)</strong> In these terms and conditions, "your content" means all works and materials (including without limitation text, graphics, images, audio material, video material, audio-visual material, scripts, software and files) that you submit to us or our website for storage or publication on, processing by, or transmission via, our website.</p>
                        <p><strong>(ii)</strong> You grant to us a worldwide, irrevocable, non-exclusive, royalty-free licence to use, reproduce, store, adapt, publish, translate and distribute your content in any existing or future media OR reproduce, store and publish your content on and in relation to this website and any successor website OR reproduce, store and, with your specific consent, publish your content on and in relation to this website.</p>
                        <p><strong>(iii)</strong> You hereby waive all your moral rights in your content to the maximum extent permitted by applicable law, and you warrant and represent that all other moral rights in your content have been waived to the maximum extent permitted by applicable law.</p>
                        <p><strong>(iv)</strong> You may edit your content to the extent permitted using the editing functionality made available on our website.</p>
                        <p><strong>(v)</strong> Without prejudice to our other rights under these terms and conditions, if you breach any provision of these terms and conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in any way, we may delete, unpublish or edit any or all of your content.</p>

                        <h3>8. Representations</h3>
                        <p><strong>(i)</strong> You warrant and represent that your content will comply with these terms and conditions.</p>
                        <p><strong>(ii)</strong> Your content must not be illegal or unlawful, must not infringe any person's legal rights, and must not be capable of giving rise to legal action against any person (in each case in any jurisdiction and under any applicable law).</p>
                        <p><strong>(iii)</strong> Your content, and the use of your content by us in accordance with these terms and conditions, must not:</p>
                        <ul>
                            <li>Be libelous or maliciously false.</li>
                            <li>Be obscene or indecent.</li>
                            <li>Infringe any copyright, moral right, database right, trade mark right, design right, right in passing off, or other intellectual property right.</li>
                            <li>Infringe any right of confidence, right of privacy or right under data protection legislation.</li>
                            <li>Constitute negligent advice or contain any negligent statement.</li>
                            <li>Constitute an incitement to commit a crime instructions for the commission of a crime or the promotion of criminal activity.</li>
                            <li>Be in contempt of any court, or in breach of any court order.</li>
                            <li>Be in breach of racial or religious hatred or discrimination legislation.</li>
                            <li>Be blasphemous.</li>
                            <li>Be in breach of official secrets legislation.</li>
                            <li>Be in breach of any contractual obligation owed to any person.</li>
                            <li>Depict violence in an explicit, graphic or gratuitous manner.</li>
                            <li>Be pornographic, lewd, suggestive or sexually explicit.</li>
                            <li>Be untrue, false, inaccurate or misleading.</li>
                            <li>Consist of or contain any instructions, advice or other information which may be acted upon and could, if acted upon, cause illness, injury or death, or any other loss or damage.</li>
                            <li>Constitute spam.</li>
                            <li>Be offensive, deceptive, fraudulent, threatening, abusive, harassing, anti-social, menacing, hateful, discriminatory or inflammatory.</li>
                            <li>Cause annoyance, inconvenience or needless anxiety to any person.</li>
                        </ul>

                        <h3>9. Limited warranties</h3>
                        <p><strong>(i)</strong> We do not warrant or represent:</p>
                        <ul>
                            <li>The completeness or accuracy of the information published on our website.</li>
                            <li>That the material on the website is up to date.</li>
                            <li>That the website or any service on the website will remain available.</li>
                        </ul>
                        <p><strong>(ii)</strong> We reserve the right to discontinue or alter any or all of our website services, and to stop publishing our website, at any time in our sole discretion without notice or explanation, and save to the extent expressly provided otherwise in these terms and conditions, you will not be entitled to any compensation or other payment upon the discontinuance or alteration of any website services, or if we stop publishing the website.</p>
                        <p><strong>(iii)</strong> To the maximum extent permitted by applicable law, we exclude all representations and warranties relating to the subject matter of these terms and conditions, our website and the use of our website.</p>

                        <h3>10. Breaches of these terms and conditions</h3>
                        <p><strong>(i)</strong> Without prejudice to our other rights under these terms and conditions, if you breach these terms and conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in any way, we may:</p>
                        <ul>
                            <li>Send you one or more formal warnings.</li>
                            <li>Temporarily suspend your access to our website.</li>
                            <li>Permanently prohibit you from accessing our website.</li>
                            <li>Block computers using your IP address from accessing our website.</li>
                            <li>Contact any or all of your internet service providers and request that they block your access to our website.</li>
                            <li>Commence legal action against you, whether for breach of contract or otherwise.</li>
                            <li>Suspend or delete your account on our website.</li>
                        </ul>
                        <p><strong>(ii)</strong> Where we suspend or prohibit or block your access to our website or a part of our website, you must not take any action to circumvent such suspension or prohibition or blocking (including without limitation creating and/or using a different account).</p>

                        <h3>11. Variation</h3>
                        <p><strong>(i)</strong> We may revise and make changes to our privacy policy and terms and condition or any FAQ’s or Return and Refund Policy, which are all part of privacy policy, from time to time.</p>
                        <p><strong>(ii)</strong> We reserve the right to make changes to this Privacy Policy at any time and for any reason. Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice of each such change or modification. We will alert you about those changes on our Web Site by updating the “Last Updated” date of this Privacy.</p>

                        <h3>12. Assignment</h3>
                        <p><strong>(i)</strong> You hereby agree that we may assign, transfer, sub-contract or otherwise deal with our rights and/or obligations under these terms and conditions.</p>
                        <p><strong>(ii)</strong> You may not without our prior written consent assign, transfer, sub-contract or otherwise deal with any of your rights and/or obligations under these terms and conditions.</p>

                        <h3>13. Severability</h3>
                        <p><strong>(i)</strong> If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect.</p>
                        <p><strong>(ii)</strong> If any unlawful and/or unenforceable provision of these terms and conditions would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>

                        <h3>14. Third party rights</h3>
                        <p><strong>(i)</strong> A contract under these terms and conditions is for our benefit and your benefit, and is not intended to benefit or be enforceable by any third party.</p>
                        <p><strong>(ii)</strong> The exercise of the parties rights under a contract under these terms and conditions is not subject to the consent of any third party.</p>

                        <h3>15. Entire agreement</h3>
                        <p><strong>(i)</strong> The terms and conditions, together with our privacy and cookies policy and also return and refund policy and FAQ’s shall constitute the entire agreement between you and us in relation to your use of our website and shall supersede all previous agreements between you and us in relation to your use of our website.</p>

                        <h3>16. Law and jurisdiction</h3>
                        <p><strong>(i)</strong> These terms and conditions shall be governed by and construed in accordance with Indian Law.</p>
                        <p><strong>(ii)</strong> Any disputes relating to these terms and conditions shall be subject to the jurisdiction of the courts of Delhi only.</p>

                        <h3>17. Sharing of Information</h3>
                        <p><strong>(i)</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control and/or under our common control.</p>
                        <p><strong>(ii)</strong> We disclose information if legally required to do so, or at our discretion pursuant to a request from a governmental entity or if we believe in good faith after considering your privacy interests and other factors that such action is necessary to:</p>
                        <ul>
                            <li>Conform to legal requirements or comply with legal process.</li>
                            <li>Protect our rights or property or our affiliated companies.</li>
                            <li>Prevent a crime or protect national security.</li>
                            <li>Protect the personal safety of users or the public.</li>
                        </ul>
                        <p>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.</p>
                        <p><strong>(iii)</strong> We may disclose and transfer such information to a third party who acquires any or all of hsrp. Fit units, whether such acquisition is by way of merger, consolidation or purchase of all or a substantial portion of our assets. In addition, in the event hsrp. Fit becomes the subject of an insolvency proceeding, whether voluntary or involuntary, hsrp. fitor its liquidator, administrator, receiver or administrative receiver may sell, license or otherwise dispose of such information in a transaction approved by the court. You will be notified of the sale of all or a substantial portion of our business to a third party by email or through a prominent notice posted on the Web Site.</p>

                        <h3>18. Statutory and regulatory disclosures</h3>
                        <p><strong>(i)</strong> We are registered in CIN No. U93096TN2019PTC127367, and our registration number is 127367.</p>
                        <p><strong>(ii)</strong> We are registered as “Yarya Sekur Mark Pvt. Ltd.” with Registrar body in Chennai, India are subject to rules.</p>
                        <p><strong>(iii)</strong> Our GST number is 33AABCY0891M1Z0.</p>

                        <h3>19. Our details</h3>
                        <p><strong>(i)</strong> This website is owned and operated by “Yarya Sekur Mark Pvt. Ltd.”</p>
                        <p><strong>(ii)</strong> We are registered in Chennai under registration number 127367, and our registered office is at 4th Floor, E-99, Third Avenue, Anna Nagar, Chennai, Tamil Nadu, 600102.</p>
                        <p><strong>(iii)</strong> You can contact us by support number: 8595515301.</p>

                    </div>
                </div>
            </div>
        </article>
      </main>
      <Footer/>
    </>
  )
}

export default Termsconditions
