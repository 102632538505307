import React from 'react'
import Header from './Header';
import Accordion from 'react-bootstrap/Accordion';
import '../styles/Faq.css';
import Footer from './Footer';

const Faq = () => {
  return (
    <>
    <Header />
       {/* article1 */}
       <article className="top_strip">
          <h2>FAQ</h2>
        </article>

        {/* article2 */}
        <article className='accordian-content'>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="customize-color">Is it mandatory to get a High Security Registration Plate fitted?</Accordion.Header>
              <Accordion.Body>
                It is mandatory for all vehicles registered in the State of Tamilnadu to be fitted with H.S.R.P. (C.M.V.R. 1990, H.S.R.P. Order 2019).
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>What is the procedure for getting an HSRP fitted on my vehicle?</Accordion.Header>
              <Accordion.Body>
                 For all vehicles registered in the State post 16th November 2012, the automobile dealer assists the customer in getting HSRP fitted. The customer post purchase of vehicle and submission of all documents pays for the HSRP fees at the dealer premises. The dealer ensures that all the documents and the HSRP fees is duly processed at the Regional Transport Office (RTO). The HSRP fee receipt is provided to the customer who can then seek a fitment appointment online on https://www.hsrp.fit and proceed for HSRP fitment at the RTO premises as per the appointment date and time.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Is it mandatory to seek prior appointment for fitment of HSRP on vehicle?</Accordion.Header>
              <Accordion.Body>
                Yes, it is mandatory to seek an appointment and only then proceed for fitment at the RTO premises. An appointment can be sought online at https://www.hsrp.fit              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Is it required for the vehicle owner to be present at the time of fitment?</Accordion.Header>
              <Accordion.Body>
                In ideal conditions, the registered owner of the vehicle needs to be present during HSRP fitment. However, due to any exigencies if the owner cannot be present personally, then the registered owner can authorize any individual to be present on his behalf. To download the format for the authorization letter.              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Can the HSRP be affixed at dealer premises?</Accordion.Header>
              <Accordion.Body>
                An HSRP, according to Central Motor Vehicles Act can only be affixed at a Regional Transport Office or a dealer which has been declared as a Deemed Dealer for the purpose of HSRP operations by the concerned RTO / Commissioner of Transport, Government of Gujarat. There are certain dealers across Gujarat who have been authorized to carry out all HSRP operations including fitment at their premises. Hence, the registered owner may also choose to affix HSRP on his vehicle at these Dealers.              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Can I take the HSRP and get them fitted at my residence or a local vendor?</Accordion.Header>
              <Accordion.Body>
                HSRP can only be affixed by authorized staff at an RTO or authorized dealers. It is not permissible under the CMVR (Central Motor Vehicle Rules, 1989) to get these plates affixed at any other premises.              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>My vehicle has met with an accident and its rear / Front HSRP or the TLP sticker has been damaged. How can I get them replaced?</Accordion.Header>
              <Accordion.Body>
                You can get your vehicle refitted with HSRP either at the RTO or at any of the authorized dealers. This is a chargeable service              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>My vehicle has met with an accident and its rear / Front HSRP or the TLP sticker has been damaged. How can I get them replaced?</Accordion.Header>
              <Accordion.Body>
                  You can get your vehicle refitted with HSRP either at the RTO or at any of the authorized dealers. This is a chargeable service              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>The black India foil has faded from my HSRP. Can I get the HSRP replaced?</Accordion.Header>
              <Accordion.Body>
                The Black India foil embossed on the numerals of the HSRP is an essential security feature. The HSRP is guaranteed for a period five years against any defects arising out of natural wear and tear. The HSRP would hence either be re embossed or replaced free of cost at any of the authorized HSRP centres.              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>I have lost my appointment slip. What can I do now?</Accordion.Header>
              <Accordion.Body>
                  You could visit https://www.hsrp.fit and download / reprint a duplicate appointment slip.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </article>
        <Footer />
    </>
  )
}

export default Faq
